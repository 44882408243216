
import { Col } from "react-bootstrap";
import "../../../Home/components/HomeProducts/featured.css"
import React, {
  useContext,
  useState,
  useEffect,
} from "react";
import { IoMdStar } from "react-icons/io";
import { Link, NavLink } from "react-router-dom";
import { CiStar } from "react-icons/ci";
import { FiShoppingCart } from "react-icons/fi";
import sanityClient from "../../../../utils/client";

const LenseProducts = ({ _id }) => {
  const [products, setProducts] = useState([]);
  useEffect(() => {
    sanityClient
      .fetch(
        `*[_type == "lense"]{
        id,
        path,
        name,
        content,
        content1,
        content2,
        content3,
        price,
        images{
            asset->{
            url
            },
        },
        bannerImage{
          asset->{
          url
          },
      },
      hexCode,
    }`
      )
      .then((data) => setProducts(data))
      .catch(console.error);
  }, []);

  return (
    <>
      {products.map((product) => {
        return (
          
          <Col
            md={2}
            sm={4}
            xs={5}
            className="product"
            key={product._id}
          >
            <Link
              to={`${product.path}${product.id}`}
            >
              <img
                src={
                  product?.bannerImage.asset.url
                }
                alt="product-img"
              />
            </Link>
            <h3>{product.name}</h3>
            <div className="price">
              <h3>
                UGX{" "}
                {product.price?.toLocaleString()}
              </h3>
              <NavLink
              // onClick={}
              >
                <FiShoppingCart
                  size={20}
                  color="#FF6347"
                />
              </NavLink>
            </div>
          </Col>
        );
      })}
    </>
  );
};

export default LenseProducts;



