
import { Row } from "react-bootstrap";
import { memo, useEffect, useState } from "react";
import PropTypes from "prop-types";
import FeaturedProducts from "./HomeProducts";
import MayLike from "../Maylike/Maylike";

const HomeProducts = () => {
  return (
    <>
      <Row className="justify-content-center">
        <h3
          style={{
            fontSize: "25px",
            lineHeight: "55px",
            textAlign: "center",
            fontFamily: "Times New Roman",
            marginTop: "0px",
            color: "#FF6347",
          }}
        >
          Featured Products
        </h3>
        <FeaturedProducts />
      </Row>
      <MayLike />
    </>
  );
};
export default memo(HomeProducts);

HomeProducts.propTypes = {
  product: PropTypes.array,
  onAddToCart: PropTypes.func,
};
