import { TypeAnimation } from 'react-type-animation';

const Animation = () => {
  return (
    <TypeAnimation
      sequence={[
        'Located at Titanic Plaza TB03', // Types 'One'
        2000, // Waits 1s
        'Wilson Road', // Deletes 'One' and types 'Two'
        2000, // Waits 2s
        'Contact Us:+256 775 164577', 9000, // Types 'Three' without deleting 'Two'
        () => {
          console.log('Sequence completed'); // Place optional callbacks anywhere in the array
        }
      ]}
      wrapper="span"
      cursor={true}
      repeat={Infinity}
      style={{ fontSize: '13px', display: 'inline-block', textAlign: 'center' }}
    />
  );
};

export default Animation;