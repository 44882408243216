import "./App.scss";
import { Route, Routes } from "react-router-dom";
import Footer from "./components/Footer/Footer";
import Home from "./pages/Home/Home";
import Shop from "./pages/Shop/Shop";
import NotFound from "./pages/NotFound/NotFound";
import "bootstrap/dist/css/bootstrap.css";
import Product from "./pages/Product/Product";
import Lense from "./pages/Shop/Lense";
import SplashScreen from "./components/Splash/Splash";
import AnimatedCursor from "./hooks/AnimatedCursor";
import { useEffect, useState } from "react";
import Accessories from "./pages/Shop/Accessories";

import NavBar from "./components/Header/Navbar";
import MayLike from "./pages/Home/components/Maylike/Maylike";
import ScrollTop from "./components/ScrollTop";
import Whatsapp from "./components/Whatsapp";
import Scroll from "./components/Scroll";
import { About } from "./components/About/About";
import WhatsappOrder from "./pages/Product/WhatsappOrder";
import HomeProducts from "./pages/Home/components/HomeProducts/FeaturedProducts";

function App() {
  const [showSplash, setShowSplash] =
    useState(true);

  useEffect(() => {
    setTimeout(() => {
      setShowSplash(false);
    }, 3000); // 3000ms (3 seconds) is the duration of the splash screen
  }, []);

  return (
    <>
      <div className="cursor__dot">
        <AnimatedCursor
          innerSize={15}
          outerSize={15}
          color="000, 000 ,000"
          outerAlpha={0.4}
          innerScale={0.7}
          outerScale={5}
        />
      </div>

      {showSplash ? (
        <SplashScreen />
      ) : (
        <>
          <NavBar />

          <Scroll />

          <Routes>
            <Route path="/" element={<Home />} />
            <Route
              path="/shop"
              element={<Shop />}
            />

            <Route
              path="/shop/:id"
              element={<Product />}
            />
            <Route
              path="/lense"
              element={<Lense />}
            />

            <Route
              path="/accessory"
              element={<Accessories />}
            />

            <Route
              path="/about"
              element={<About />}
            />
            <Route
              path="/featured"
              element={<HomeProducts />}
            />

            <Route
              path="*"
              element={<NotFound />}
            />
          </Routes>

          {/* <MayLike /> */}
          <ScrollTop />
          {/* <Whatsapp /> */}
          {/* <Footer /> */}
        </>
      )}
    </>
  );
}

export default App;
