import { Container, Row } from "react-bootstrap";
import { useState, useEffect } from "react";
import sanityClient from "../../../../utils/client"
import CategoryCard from "./CategoryCard";

const Categories = ({ bgColor, title }) => {
  const [product, setProduct] = useState(null);
  useEffect(() => {
    sanityClient
      .fetch(
        `*[_type == "category"]{
      title,
        image{
          asset->{
          url
          },
      },
      hexCode,
    }`
      )
      .then((data) => setProduct(data))
      .catch(console.error);
  }, []);
  return (
    <section style={{ background: bgColor }}>
      <Container>
        <div
          className="heading"
          style={{
            fontSize: "25px",
            lineHeight: "55px",
            textAlign: 'center',
            fontFamily: "Times New Roman",
            marginTop: "50px",
            color: "#FF6347",
          }}
        >
          <h1>Categories</h1>
        </div>

        <Row className="justify-content-center">
          {product?.map((productItem) => {
            return (
              <CategoryCard
                key={productItem.id}
                image={
                  productItem.image.asset.url
                }
                title={productItem.title}
              />
            );
          })}
        </Row>
      </Container>
    </section>
  );
};

export default Categories;
