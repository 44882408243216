import {
    Col,
    Container,
    Row,
  } from "react-bootstrap";
  import "./slidecard.css";
  import { NavLink } from "react-router-dom";
  
  const SlideCard = ({ name, desc, cover }) => {
    return (
      <Container className="box">
        <Row>
          <Col md={6}>
            <h1>{name}</h1>
            <p>{desc}</p>
            <NavLink to="/shop">
              <button
                className="btn-primary"
                style={{
                  width: "150px",
                  height: "50px",
                  borderRadius: "10px",
                  backgroundColor: "#32322c",
                  color: "#fff",
                  textDecoration: "none"
                }}
              >
                Shop Now
              </button>
            </NavLink>
          </Col>
          <Col md={4}>
            <img
              src={cover}
              alt="#"
            />
          </Col>
        </Row>
      </Container>
    );
  };
  
  export default SlideCard;