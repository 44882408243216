import React from "react";

import HomeCollection from "./components/HomeCollection/HomeCollection";

import HomeProducts from "./components/HomeProducts/FeaturedProducts";
import Footer from "../../components/Footer/Footer";
import Categories from "./components/Categories/Category";
import TopSelling from "./components/Top/TopSelling";

const Home = () => {
  return (
    <div id="home">
      <HomeCollection />
      <Categories />
      <TopSelling />
      {/* <HomeProducts /> */}
      <Footer />
    </div>
  );
};

export default Home;
