import { Row } from "react-bootstrap";
import { memo, useEffect, useState } from "react";
import PropTypes from "prop-types";
import TopCard from "./TopCard";


const TopSelling = () => {
  return (
    <Row className="justify-content-center">
      <TopCard />
    </Row>
  );
};
export default memo(TopSelling);

TopSelling.propTypes = {
  product: PropTypes.array,
  onAddToCart: PropTypes.func,
};