import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Container } from "react-bootstrap";
import SlideCard from "./SlideCard";
import React, {
  useState,
  useEffect,
} from "react";

import Carousel from "framer-motion-carousel";
import sanityClient from "../../../../utils/client"

const HomeCollection = () => {
  const [product, setProduct] = useState(null);
  useEffect(() => {
    sanityClient
      .fetch(
        `*[_type == "banner"]{
        name,
        desc,
        image{
          asset->{
          url
          },
      },
      hexCode,
    }`
      )
      .then((data) => setProduct(data))
      .catch(console.error);
  }, []);
  return (
    <section
      className="homeSlide"
      style={{ backgroundColor: "#fff" }}
    >
      <Container>
        <Carousel
          interval={6000}
          renderArrowLeft={() => null}
          renderArrowRight={() => null}
          renderDots={() => true}
        >
          {product && product.map((value, index) => {
            return (
              <SlideCard
                key={index}
                name={value.name}
                cover={
                  value.image.asset.url
                }
                desc={value.desc}
              />
            );
          })}
        </Carousel>
      </Container>
    </section>
  );
};

export default HomeCollection;


