const data = [
  {
    title: "Silicon Camera Covers",
    image: require("../../../../assets/images/cover.webp"),
    price: 6200000,
  },
  {
    title: "Canon R Mirrorless",
    image: require("../../../../assets/images/canonr.webp"),
    price: 6200000,
  },
  {
    title: "Fujifilm Tele Converter",
    image: require("../../../../assets/images/film.webp"),
    price: 6200000,
  },
  {
    title: "Godox Light Bags",
    image: require("../../../../assets/images/godox.webp"),
    price: 6200000,
  },
  {
    title: "Fujinon 110mm",
    image: require("../../../../assets/images/fuji1.webp"),
    price: 6200000,
  },
  {
    title: "Nikkor Z 85mm",
    image: require("../../../../assets/images/z.webp"),
    price: 6200000,
  },
  {
    title: "Nikon D7100",
    image: require("../../../../assets/images/d7.webp"),
    price: 6200000,
  },

];

export default data;
