// import React, { useState } from "react";
// import { Link } from "react-router-dom";

// import { AiOutlineCopyright } from "react-icons/ai";
// import {
//   AiOutlineMail,
//   AiFillPhone,
// } from "react-icons/ai";
// import Animation from "../Animation";

// const Footer = () => {
//   const [subValue, setSubValue] = useState("");

//   const currYear = new Date().getFullYear();

//   return (
//     <footer id="footer">
//       <div className="sub_footer">
//         <div className="container">
//           <div className="sub_footer_wrapper">
//             <div
//               className="foot_copyright"
//               style={{
//                 fontFamily: "Barlow",
//                 color: "#fff",
//               }}
//             >
//               <p>
//                 <AiOutlineCopyright />
//                 AUSLUK LTD <span>{currYear}</span>
//               </p>
//               <p>
//                 <a
//                   href="https://www.linkedin.com/in/sekitto-andrew-9120351b2/"
//                   target="_blank"
//                   color="#fff"
//                 >
//                   poweredBy AndreySek
//                 </a>
//                 <span></span>
//               </p>
//             </div>
//             <div className="foot_social">
//               <a href="mailto:kyeyunefred07@yahoo.com">
//                 <AiOutlineMail size={35} />
//               </a>
//               <a href="tel:+256775164577">
//                 <AiFillPhone size={35} />
//               </a>
//             </div>
//           </div>
//           <Animation />
//         </div>
//       </div>
//     </footer>
//   );
// };

// export default Footer;

import React from "react";
import "./footer.css";
import {
  Col,
  Container,
  Row,
} from "react-bootstrap";
import {
  TbCopyright,
  TbMail,
  TbPerfume,
  TbPhoneCall,
} from "react-icons/tb";
import {
  BsFacebook,
  BsInstagram,
  BsPhone,
  BsSnapchat,
} from "react-icons/bs";
import {
  AiOutlineMail,
  AiFillPhone,
} from "react-icons/ai";
import Animation from "../Animation";

const currYear = new Date().getFullYear();

const Footer = () => {
  return (
    <footer>
      <Container>
        <Row className="footer-row">
          <Col md={2} sm={5} className="box">
            <div className="logo">
              <img
                src={require("../../assets/logo.png")}
                style={{
                  width: "100px",
                  borderRadius: "20px",
                }}
              />
            </div>
          </Col>
          <Col
            md={3}
            sm={5}
            className="box"
            style={{ color: "#fff" }}
          >
            <h3
              style={{
                color: "#fff",
                fontSize: "25px",
              }}
            >
              Ausluk LTD
            </h3>
            <ul>
              <li>Your Favorite Camera Store</li>
              <li>
                copyright@
                {currYear} Ausluk LTD
              </li>
              <li>
                <Animation />
              </li>
              <li>
                <a
                  href="https://www.linkedin.com/in/sekitto-andrew-9120351b2/"
                  target="_blank"
                  style={{
                    textDecoration: "underline",
                    color: "#fff",
                  }}
                >
                  poweredBy AndreySek
                </a>
              </li>
            </ul>
          </Col>
          <Col md={3} sm={5}>
            <ul>
              <li>
                <a href="mailto:kyeyunefred07@yahoo.com">
                  <AiOutlineMail
                    size={35}
                    size={20}
                    color="#fff"
                    style={{
                      marginLeft: "30px",
                      cursor: "pointer",
                      // marginTop: "10px"
                    }}
                  />
                </a>
                <a href="tel:+256775164577">
                  <TbPhoneCall
                    size={20}
                    color="#fff"
                    style={{
                      marginLeft: "30px",
                      cursor: "pointer",
                      // marginTop: "10px"
                    }}
                  />
                </a>
              </li>
            </ul>
          </Col>
        </Row>
      </Container>
    </footer>
  );
};

export default Footer;
