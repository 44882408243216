const meta = {
  title: "Ausluk LTD",
};

const dataabout = {
  title: "Ausluk LTD",
  aboutme:
    "Ausluk LTD is a camera shop located in Kampala Uganda and  we sell all camera related products. Our passion for photography drives everything we do. We believe that capturing the perfect moment is an art form, and we are here to empower photographers of all levels with the best tools and expertise to achieve their creative vision. Our journey began in 2010, and since then, we have grown to become a trusted name in the world of photography equipment and accessories.",
};

const services = [
  {
    title: "Cameras",
    description:
      "We sell affordable cameras in Kampala Uganda. Shop our exclusive selection of camera brands and models at our online store and make photography easy. You can as well upgrade your gear with our latest camera models.",
  },
  {
    title: "Lenses",
    description:
      "Capture the Perfect Shot  with our Professional-Grade Optic Lenses.",
  },
  {
    title: "Accessories",
    description:
      "Our accessories are built to last, even in challenging conditions. High value and at affordable prices. You can as well make an order for a specific accessory and we can ship in a week's time",
  },
];

export { meta, dataabout, services };
