import React, {
  useContext,
  useEffect,
  useState,
} from "react";
import {
  Link,
  NavLink,
  useParams,
} from "react-router-dom";
import {
  IoMdStar,
  IoMdCheckmark,
} from "react-icons/io";
import useActive from "../../hooks/useActive";
import { data } from "../../data/data";

// import Heading from "../components/Heading";
import Whatsapp from "./WhatsappOrder";

const Product = () => {
  //   useDocTitle("Product Details");

  const { handleActive, activeClass } =
    useActive(0);

  //   const { addItem } = useContext(cartContext);

  const { id } = useParams();

  // here the 'id' received has 'string-type', so converting it to a 'Number'
  const prodId = parseInt(id);

  // showing the Product based on the received 'id'
  const product = data.find(
    (item) => item.id === prodId
  );
  const {
    images,
    title,
    bannerImage,
    // info,
    // category,
    price,
    content,
    content1,
    content2,
    content3,
    // ratings,
    // rateCount,
  } = product;

  const [previewImg, setPreviewImg] = useState(
    images[0]
  );

  useEffect(() => {
    setPreviewImg(images[0]);
    handleActive(0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [images]);

  const handlePreviewImg = (i) => {
    setPreviewImg(images[i]);
    handleActive(i);
  };

  const handleWhatsAppShare = () => {
    const message = `I'd like to order ${title} . Please confirm.`;
    const whatsappURL = `https://wa.me/+256775164577?text=${encodeURIComponent(
      message
    )}`;

    // Open a new tab or window with the WhatsApp URL
    window.open(whatsappURL, "_blank");
  };

  return (
    <>
      <section
        id="product_details"
        className="section"
      >
        <div className="container">
          <div className="wrapper prod_details_wrapper">
            {/*=== Product Details Left-content ===*/}
            <div className="prod_details_left_col">
              <div className="prod_details_tabs">
                {images.map((img, i) => (
                  <div
                    key={i}
                    className={`tabs_item ${activeClass(
                      i
                    )}`}
                    onClick={() =>
                      handlePreviewImg(i)
                    }
                  >
                    <img
                      src={img}
                      alt="product-img"
                      width={200}
                    />
                  </div>
                ))}
              </div>
              <figure className="prod_details_img">
                <img
                  src={previewImg}
                  alt="product-img"
                  width={350}
                />
              </figure>
            </div>

            {/*=== Product Details Right-content ===*/}
            <div className="prod_details_right_col">
              <h1
                className="prod_details_title"
                style={{ fontFamily: "Barlow" }}
              >
                {title}
              </h1>
              <h4
                className="prod_details_info"
                style={{ fontFamily: "Barlow" }}
              >
                {content}
              </h4>
              <h4
                className="prod_details_info"
                style={{ fontFamily: "Barlow" }}
              >
                {content1}
              </h4>
              <h4
                className="prod_details_info"
                style={{ fontFamily: "Barlow" }}
              >
                {content2}
              </h4>
              <h4
                className="prod_details_info"
                style={{ fontFamily: "Barlow" }}
              >
                {content3}
              </h4>

              <div className="prod_details_ratings"></div>

              <div className="separator"></div>

              <div className="prod_details_price">
                <div className="price_box">
                  <span className="tax_txt">
                    (Delivery cost not inclusive)
                  </span>
                </div>

                <div className="badge">
                  <span>
                    <IoMdCheckmark /> In Stock
                  </span>
                </div>
              </div>

              <div className="prod_details_buy_btn">
                <button
                  type="button"
                  className="btn"
                  onClick={handleWhatsAppShare}
                >
                  Order
                </button>
              </div>

              <div className="separator"></div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Product;
