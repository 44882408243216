import { Col } from "react-bootstrap";
import "./category.css"
import {
  Link,
  NavLink,
  useNavigate,
} from "react-router-dom";

const CategoryCard = ({ image, title }) => {

 
  return (
    <Col
      md={2}
      sm={5}
      xs={10}
      className="arrivals"
    >
      <NavLink
        style={{ textDecoration: "none" }}
      >
        <img
          loading="lazy"
        //   onClick={() => handelClick()}
          src={image}
          alt=""
          style={{
            background:
              "linear-gradient(#fff, #fff, #fff)",
          }}
        />
        <div className="product-details">
          <div>
            <h4
              style={{
                textAlign: "center",
                fontSize: "20px",
                fontFamily: "Times New Roman",
                color: "#fff",       
              }}
            >
              {title}
            </h4>
          </div>
        </div>
      </NavLink>
    </Col>
  );
};

export default CategoryCard;