export const data = [
  {
    id: 1,
    tag: "featured",
    category: "Camera",
    title: "Canon HD XA11",
    content:
      "⭐︎ High-Definition Video: The XA11 records video in full HD (1080p) resolution, delivering high-quality video footage.",
    content1:
      "⭐︎ Built-in Wi-Fi: You can control the camcorder remotely, transfer footage wirelessly, or live-stream using the built-in Wi-Fi capability.",
    content2:
      "⭐︎ Professional Audio: The XA11 features XLR audio inputs and provides professional-level audio recording with phantom power support for external microphones.",
    content3:
      "⭐︎ LCD Touchscreen: The camcorder has a 3.0-inch LCD touchscreen for easy menu navigation and monitoring of your shots.",
    bannerImage: require("../assets/images/xall3.webp"),
    images: [
      require("../assets/images/xa11.webp"),
      require("../assets/images/xa112.webp"),
      require("../assets/images/xall3.webp"),
    ],
    price: 6200000,
    path: "/shop/",
  },
  {
    id: 2,
    category: "Camera",
    title: "Panasonic HC PV 100",
    content:
      "⭐︎ Full HD Video: The HC-PV100 records video in full high-definition (1080p) resolution, providing sharp and clear video footage.",
    content1:
      "⭐︎ 1/3.1-inch BSI Sensor: It features a 1/3.1-inch back-illuminated sensor, which enhances low-light performance and delivers excellent image quality.",
    content2:
      "⭐︎ Built-in Video Light: It includes a built-in video light that is particularly useful in low-light shooting conditions.",
    content3:
      "⭐︎ LCD Touchscreen: The camcorder has a 3.0-inch LCD touchscreen for easy menu navigation and monitoring of your shots.",
    bannerImage: require("../assets/images/pv100.webp"),
    images: [
      require("../assets/images/pv100.webp"),
      require("../assets/images/pv1002.webp"),
      require("../assets/images/pv1003.webp"),
    ],
    price: 6200000,
    path: "/shop/",
  },
  {
    id: 3,
    category: "Camera",
    title: "Sony Camera 7rv",
    content:
      "⭐︎ Featuring a 61MP full-frame sensor.",
    content1:
      "⭐︎ All-new AI-based autofocus system with advanced subject recognition.",
    content2:
      "⭐︎ 8K video recording, and 8-stop image stabilization.",
    content3:
      "⭐︎ The Sony a7R V is a high resolution mirrorless camera, combining an excellent sensor with a powerful autofocus system.",
    bannerImage: require("../assets/images/a7rv.webp"),
    images: [
      require("../assets/images/a7rv2.webp"),
      require("../assets/images/a7rv3.webp"),
      require("../assets/images/a7rv.webp"),
    ],
    price: 6200000,
    path: "/shop/",
  },
  {
    id: 4,
    category: "Camera",
    title: "Canon R Mirrorless",
    content:
      "⭐︎ Canon EOS R: The original EOS R featured a 30.3-megapixel full-frame sensor, Dual Pixel CMOS AF, and a fully articulated touchscreen.",
    content1:
      "⭐︎ Canon EOS R5: The EOS R5 was a flagship mirrorless camera that featured a 45-megapixel full-frame sensor and was known for its impressive 8K video recording capabilities.",
    content2:
      "⭐︎ Professional Audio: The XA11 features XLR audio inputs and provides professional-level audio recording with phantom power support for external microphones.",
    content3:
      "⭐︎ The EOS RP was a more compact and affordable full-frame mirrorless camera. It featured a 26.2-megapixel sensor.",
    bannerImage: require("../assets/images/canonr.webp"),
    images: [
      require("../assets/images/canonr.webp"),
      require("../assets/images/canonr2.webp"),
      require("../assets/images/canonr3.webp"),
    ],
    price: 6200000,
    path: "/shop/",
  },
  {
    id: 5,
    category: "Camera",
    title: "Canon EOS R7",
    content:
      "⭐︎ Whether you're shooting stills or video, the EOS R7's 32.5 megapixel CMOS sensor and DIGIC X image process deliver stunning results.",
    content1:
      "⭐︎ The Canon EOS R7 is a 32.5MP APS-C mirrorless camera built around Canon's RF mount.",
    content2:
      "⭐︎ The R7 adds 4K video without recording limits, is a solid choice for sport/wildlife shooters wanting that “extra reach”.",
    content3:
      "⭐︎ An ideal blend of performance and portability, the EOS R7 represents Canon's move into APS-C with the mirrorless R system.",
    bannerImage: require("../assets/images/r7.webp"),
    images: [
      require("../assets/images/r72.webp"),
      require("../assets/images/r73.webp"),
      require("../assets/images/r7.webp"),
    ],
    price: 6200000,
    path: "/shop/",
  },
  {
    id: 6,
    tag: "featured",
    category: "Camera",
    title: "Canon EOS R6",
    content:
      "⭐︎ Sensor: The EOS R6 features a 20.1-megapixel full-frame CMOS sensor, which provides excellent image quality and low-light performance.",
    content1:
      "⭐︎ Built-in Wi-Fi: You can control the camcorder remotely, transfer footage wirelessly, or live-stream using the built-in Wi-Fi capability.",
    content2:
      "⭐︎ Professional Audio: The XA11 features XLR audio inputs and provides professional-level audio recording with phantom power support for external microphones.",
    content3:
      "⭐︎ LCD Touchscreen: The camcorder has a 3.0-inch LCD touchscreen for easy menu navigation and monitoring of your shots.",
    bannerImage: require("../assets/images/r62.webp"),
    images: [
      require("../assets/images/r62.webp"),
      require("../assets/images/r63.webp"),
      require("../assets/images/r6.webp"),
    ],
    price: 6200000,
    path: "/shop/",
  },
  {
    id: 7,
    category: "Camera",
    title: "Canon XA50 4K",
    content:
      "⭐︎ High-Definition Video: The XA11 records video in full HD (1080p) resolution, delivering high-quality video footage.",
    content1:
      "⭐︎ Built-in Wi-Fi: You can control the camcorder remotely, transfer footage wirelessly, or live-stream using the built-in Wi-Fi capability.",
    content2:
      "⭐︎ Professional Audio: The XA11 features XLR audio inputs and provides professional-level audio recording with phantom power support for external microphones.",
    content3:
      "⭐︎ LCD Touchscreen: The camcorder has a 3.0-inch LCD touchscreen for easy menu navigation and monitoring of your shots.",
    bannerImage: require("../assets/images/xa50.webp"),
    images: [
      require("../assets/images/xa501.webp"),
      require("../assets/images/xa502.webp"),
      require("../assets/images/xa50.webp"),
    ],
    price: 6200000,
    path: "/shop/",
  },
  {
    id: 8,
    category: "Camera",
    title: "Nikon 730",
    content:
      "⭐︎ High-Definition Video: The XA11 records video in full HD (1080p) resolution, delivering high-quality video footage.",
    content1:
      "⭐︎ Image Stabilization: It incorporates in-body image stabilization (IBIS) to reduce camera shake and enhance image and video stability.",
    content2:
      "⭐︎ Continuous Shooting: The camera has an impressive burst rate of up to 20 frames per second (fps) with the electronic shutter and 12 fps with the mechanical shutter.",
    content3:
      "⭐︎ Weather Sealing: The EOS R6 is weather-sealed, making it suitable for shooting in various environmental conditions.",
    bannerImage: require("../assets/images/z30.webp"),
    images: [
      require("../assets/images/z301.webp"),
      require("../assets/images/z302.webp"),
      require("../assets/images/z30.webp"),
    ],
    price: 6200000,
    path: "/shop/",
  },
  {
    id: 9,
    category: "Camera",
    title: "Sony 4D 6400",
    content:
      "⭐︎ The α6400 offers internal 4K (QFHD: 3840 x 2160) recording2 in Super 35mm format.",
    content1:
      "⭐︎ Explore Sony a6400 APS-C Mirrorless Interchangeable Lens Camera with the fastest AF and 11fps continuous shooting so you don't miss any action.",
    content2:
      "⭐︎ Sony Alpha 6400 | APS-C Mirrorless Camera (Fast 0.02s Autofocus, 24.2 Megapixels, 4K Movie Recording, Flip Screen for Vlogging).",
    content3:
      "⭐︎ Speedy, reliable autofocus. Real-time Eye AF. Incredible image quality.",
    bannerImage: require("../assets/images/400.webp"),
    images: [
      require("../assets/images/4002.webp"),
      require("../assets/images/4003.webp"),
      require("../assets/images/400.webp"),
    ],
    price: 6200000,
    path: "/shop/",
  },
  {
    id: 10,
    category: "Camera",
    title: "Panasonic AG UX90mc",
    content:
      "⭐︎ 4K Ultra HD Recording: The AG-UX90MC can record video in 4K Ultra HD resolution (3840 x 2160 pixels), delivering high-quality video footage.",
    content1:
      "⭐︎ Dual SD Card Slots: The AG-UX90MC has dual SD card slots, allowing for extended recording time and simultaneous backup recording.",
    content2:
      "⭐︎ Professional Audio: The camcorder includes two XLR audio inputs, making it suitable for professional audio recording with phantom power support for external microphones.",
    content3:
      "⭐︎ Manual Controls: The AG-UX90MC provides manual control over focus, zoom, iris, and other settings, giving videographers more creative control.",
    bannerImage: require("../assets/images/ag.webp"),
    images: [
      require("../assets/images/ag2.webp"),
      require("../assets/images/ag3.webp"),
      require("../assets/images/ag.webp"),
    ],
    price: 6200000,
    path: "/shop/",
  },
  {
    id: 11,
    category: "Camera",
    title: "Canon EOS R5",
    content:
      "⭐︎ High-Definition Video: Records video in full HD (1080p) resolution, delivering high-quality video footage.",
    content1:
      "⭐︎ Built-in Wi-Fi: You can control remotely, transfer footage wirelessly, or live-stream using the built-in Wi-Fi capability.",
    content2:
      "⭐︎ Professional Audio: Features audio inputs and provides professional-level audio recording with phantom power support for external microphones.",
    content3:
      "⭐︎ LCD Touchscreen: Easy menu navigation and monitoring of your shots.",
    bannerImage: require("../assets/images/r5.webp"),
    images: [
      require("../assets/images/r52.webp"),
      require("../assets/images/r53.webp"),
      require("../assets/images/r5.webp"),
    ],
    price: 6200000,
    path: "/shop/",
  },
  {
    id: 12,
    tag: "featured",
    category: "Camera",
    title: "Fuji GFX 50s",
    content:
      "⭐︎ Medium Format Sensor: The GFX 50S features a large 51.4-megapixel medium format CMOS sensor, which is significantly larger than full-frame sensors.",
    content1:
      "⭐︎ Interchangeable Lenses: The GFX system has a range of interchangeable lenses designed specifically for the larger sensor, providing excellent optical quality and versatility.",
    content2:
      "⭐︎ In-Body Image Stabilization: The GFX 50S features in-body image stabilization to reduce camera shake and enhance image stability.",
    content3:
      "⭐︎ High-Quality Build: The camera body is made from magnesium alloy for durability and a premium feel.",
    bannerImage: require("../assets/images/fuji.webp"),
    images: [
      require("../assets/images/fuji2.webp"),
      require("../assets/images/fuji3.webp"),
      require("../assets/images/fuji.webp"),
    ],
    price: 6200000,
    path: "/shop/",
  },
  {
    id: 13,
    category: "Camera",
    title: "Sony FX30",
    content:
      "⭐︎ High-Definition Video: Records video in full HD (1080p) resolution, delivering high-quality video footage.",
    content1:
      "⭐︎ Built-in Wi-Fi: You can control the camcorder remotely, transfer footage wirelessly, or live-stream using the built-in Wi-Fi capability.",
    content2:
      "⭐︎ Professional Audio: Audio inputs and provides professional-level audio recording with phantom power support for external microphones.",
    content3:
      "⭐︎ LCD Touchscreen: LCD touchscreen for easy menu navigation and monitoring of your shots.",
    bannerImage: require("../assets/images/fx.webp"),
    images: [
      require("../assets/images/fx2.webp"),
      require("../assets/images/fx3.webp"),
      require("../assets/images/fx.webp"),
    ],
    price: 6200000,
    path: "/shop/",
  },
  {
    id: 14,
    category: "Camera",
    title: "Osmo Action 3",
    content:
      "⭐︎ High-Definition Video: Full HD (1080p) resolution, delivering high-quality video footage.",
    content1:
      "⭐︎ Built-in Wi-Fi: You can control remotely, transfer footage wirelessly, or live-stream using the built-in Wi-Fi capability.",
    content2:
      "⭐︎ Professional Audio: Provides professional-level audio recording with phantom power support for external microphones.",
    content3:
      "⭐︎ LCD Touchscreen: LCD touchscreen for easy menu navigation and monitoring of your shots.",
    bannerImage: require("../assets/images/osmo.webp"),
    images: [
      require("../assets/images/osmo2.webp"),
      require("../assets/images/osmo3.webp"),
      require("../assets/images/osmo.webp"),
    ],
    price: 6200000,
    path: "/shop/",
  },
  {
    id: 15,
    category: "Camera",
    title: "Nikon D7100",
    content:
      "⭐︎ Image Sensor: The D7100 features a 24.1-megapixel APS-C CMOS sensor, which provides high-resolution images and good low-light performance.",
    content1:
      "⭐︎ Image Processor: It uses the EXPEED 3 image processor, which helps with image processing speed and noise reduction.",
    content2:
      "⭐︎ Autofocus System: The D7100 utilizes a 51-point autofocus system, with 15 cross-type sensors, for fast and accurate focusing.",
    content3:
      "⭐︎ Wireless Connectivity: The D7100 includes built-in Wi-Fi for wireless image transfer and remote camera control via Nikon's mobile app.",
    bannerImage: require("../assets/images/d7.webp"),
    images: [
      require("../assets/images/d72.webp"),
      require("../assets/images/d73.webp"),
      require("../assets/images/d7.webp"),
    ],
    price: 6200000,
    path: "/shop/",
  },
  {
    id: 16,
    category: "Camera",
    title: "Nikon D7000",
    content:
      "⭐︎ Autofocus System: The D7100 utilizes a 51-point autofocus system, with 15 cross-type sensors, for fast and accurate focusing.",
    content1:
      "⭐︎ Built-in Wi-Fi: You can control remotely, transfer footage wirelessly, or live-stream using the built-in Wi-Fi capability.",
    content2:
      "⭐︎ Professional Audio: The XA11 features XLR audio inputs and provides professional-level audio recording with phantom power support for external microphones.",
    content3:
      "⭐︎ Wireless Connectivity: Includes built-in Wi-Fi for wireless image transfer and remote camera control via Nikon's mobile app.",
    bannerImage: require("../assets/images/d.webp"),
    images: [
      require("../assets/images/d2.webp"),
      require("../assets/images/d3.webp"),
      require("../assets/images/d.webp"),
    ],
    price: 6200000,
    path: "/shop/",
  },
  {
    id: 17,
    category: "Camera",
    title: "Canon EOS R",
    content:
      "⭐︎ High-Definition Video: Records video in full HD (1080p) resolution, delivering high-quality video footage.",
    content1:
      "⭐︎ Built-in Wi-Fi: You can control the it remotely, transfer footage wirelessly, or live-stream using the built-in Wi-Fi capability.",
    content2:
      "⭐︎ Professional Audio: Features XLR audio inputs and provides professional-level audio recording with phantom power support for external microphones.",
    content3:
      "⭐︎ LCD Touchscreen: LCD touchscreen for easy menu navigation and monitoring of your shots.",
    bannerImage: require("../assets/images/r.webp"),
    images: [
      require("../assets/images/r2.webp"),
      require("../assets/images/r3.webp"),
      require("../assets/images/r.webp"),
    ],
    price: 6200000,
    path: "/shop/",
  },
  {
    id: 18,
    category: "Lenses",
    title: "EW-83H",
    content:
      "⭐︎ The EW-83H lens hood is known to be designed for Canon EF 24-105mm f/4L IS USM and EF 24-70mm f/4L IS USM lenses.",
    content1:
      "⭐︎ To use the EW-83H lens hood, you typically need to attach it to the lens's filter thread.",
    content2:
      "⭐︎ The EW-83H is a lens hood designed for specific Canon camera lenses.",
    content3:
      "⭐︎ They are particularly useful when shooting in bright or backlit conditions.",
    bannerImage: require("../assets/images/ew1.webp"),
    images: [
      require("../assets/images/ew2.webp"),
      require("../assets/images/ew3.webp"),
      require("../assets/images/ew1.webp"),
    ],
    price: 6200000,
    path: "/shop/",
  },
  {
    id: 19,
    category: "Lenses",
    title: "Nikon DX",
    content:
      "⭐︎ Sensor Size: The DX-format sensor is approximately 24mm x 16mm in size, which is smaller than the full-frame FX sensor.",
    content1:
      "⭐︎ DSLR Cameras: Nikon's DX-format sensors are commonly found in their consumer and enthusiast DSLR cameras.",
    content2:
      "⭐︎ Lenses: Nikon offers a wide range of DX-format lenses optimized for these cameras.",
    content3:
      "⭐︎ Telephoto Advantage: The DX format can provide an advantage for telephoto photography due to the crop factor.",
    bannerImage: require("../assets/images/dx1.webp"),
    images: [
      require("../assets/images/dx2.webp"),
      require("../assets/images/dx3.webp"),
      require("../assets/images/dx1.webp"),
    ],
    price: 6200000,
    path: "/shop/",
  },
  {
    id: 20,
    category: "Lenses",
    title: "Canon Ultra Sonic",
    content:
      "⭐︎ Exceptional build quality and reliability.",
    content1:
      "⭐︎ Reduced noise during focusing, making it ideal for video recording.",
    content2:
      "⭐︎ Manual focus override in autofocus (in many models) for quick manual adjustments.",
    content3:
      "⭐︎ Reduced noise during focusing, making it ideal for video recording.",
    bannerImage: require("../assets/images/ultra2.webp"),
    images: [
      require("../assets/images/ultra2.webp"),
      require("../assets/images/ultra3.webp"),
      require("../assets/images/ultra.webp"),
    ],
    price: 6200000,
    path: "/shop/",
  },
  {
    id: 21,
    tag: "featured",
    category: "Lense",
    title: "Canon EF",
    content:
      "⭐︎ Full Compatibility: Canon EF lenses are designed to work seamlessly with Canon EOS cameras. They offer full compatibility with autofocus and auto-exposure functions.",
    content1:
      "⭐︎ Fast Autofocus: Canon's EF lenses often feature fast and accurate autofocus, thanks to technologies like Ultrasonic Motor (USM) and Stepping Motor (STM) systems.",
    content2:
      "⭐︎ High-Quality Optics: Canon places a strong emphasis on optical quality and design in its EF lenses. This results in lenses that can deliver sharp, clear images.",
    content3:
      "⭐︎ Weather Sealing: Many high-end EF lenses come with weather-sealed designs to protect against dust and moisture, allowing photographers to work in challenging conditions.",
    bannerImage: require("../assets/images/ef.webp"),
    images: [
      require("../assets/images/ef2.webp"),
      require("../assets/images/ef3.webp"),
      require("../assets/images/ef.webp"),
    ],
    price: 6200000,
    path: "/shop/",
  },
  {
    id: 22,
    category: "Lenses",
    title: "Fujinon 110mm",
    content:
      "⭐︎ Large Aperture: Many 110mm lenses in the Fujinon lineup feature a relatively large maximum aperture, such as f/2 or f/2.8.",
    content1:
      "⭐︎ Optical Quality: Fujinon lenses are known for their optical quality, and the 110mm lens is no exception.",
    content2:
      "⭐︎ Weather-Sealing: Some Fujinon lenses, including the 110mm, feature weather-sealing to protect against dust and moisture.",
    content3:
      "⭐︎ Metal Build: Many Fujinon lenses are built with metal components, contributing to durability and a premium feel.",
    bannerImage: require("../assets/images/fuji1.webp"),
    images: [
      require("../assets/images/fuji22.webp"),
      require("../assets/images/fuji33.webp"),
      require("../assets/images/fuji1.webp"),
    ],
    price: 6200000,
    path: "/shop/",
  },
  {
    id: 23,
    category: "Lenses",
    title: "Fujifilm Tele Converter",
    content:
      "⭐︎ XF Teleconverters (for X-Series Cameras): These teleconverters are designed to work with Fujifilm X-series cameras and lenses.",
    content1:
      "⭐︎ Image Quality: Teleconverters can affect image quality to some extent.",
    content2:
      "⭐︎ Loss of Aperture: When using teleconverters, the effective aperture of the lens is reduced.",
    content3:
      "⭐︎ Compatibility: It's important to note that Fujifilm's teleconverters are designed for use with specific Fujinon lenses and camera models.",
    bannerImage: require("../assets/images/film.webp"),
    images: [
      require("../assets/images/film2.webp"),
      require("../assets/images/film3.webp"),
      require("../assets/images/film.webp"),
    ],
    price: 6200000,
    path: "/shop/",
  },
  {
    id: 24,
    category: "Lenses",
    title: "Nikkor Z 85mm",
    content:
      "⭐︎ This lens is known for its combination of a classic 85mm focal length and a wide aperture.",
    content1:
      "⭐︎ Wide Aperture: The f/1.8 maximum aperture allows for excellent low-light performance and creates a beautiful.",
    content2:
      "⭐︎ S-Line: The S-Line designation indicates that this lens is part of Nikon's high-quality lens lineup, known for exceptional optical performance and build quality.",
    content3:
      "⭐︎ Weather-Sealed: The lens is weather-sealed to protect against dust and moisture, making it suitable for outdoor photography in various conditions.",
    bannerImage: require("../assets/images/z.webp"),
    images: [
      require("../assets/images/z2.webp"),
      require("../assets/images/z3.webp"),
      require("../assets/images/z.webp"),
    ],
    price: 6200000,
    path: "/shop/",
  },
  {
    id: 25,
    category: "Lenses",
    title: "Canon RF 35mm",
    content:
      "⭐︎ Focal Length: The 35mm focal length is known for its versatility, making it a great choice for a wide range of photography styles.",
    content1:
      "⭐︎ Wide Aperture: The f/1.8 maximum aperture allows for excellent low-light performance and background blur (bokeh) for creative subject isolation.",
    content2:
      "⭐︎ Image Stabilization: The lens includes built-in optical image stabilization (IS), which helps reduce the effects of camera shake.",
    content3:
      "⭐︎ Compact and Lightweight: The lens has a compact and lightweight design, making it a convenient choice for travel and everyday photography.",
    bannerImage: require("../assets/images/c.webp"),
    images: [
      require("../assets/images/c2.webp"),
      require("../assets/images/c3.webp"),
      require("../assets/images/c.webp"),
    ],
    price: 6200000,
    path: "/shop/",
  },
  {
    id: 26,
    tag: "featured",
    category: "Lenses",
    title: "Sigma F2.8",
    content:
      "⭐︎ Sigma 24-70mm f/2.8 DG OS HSM Art: This lens is a versatile zoom lens available for various camera mounts.",
    content1:
      "⭐︎ Sigma 70-200mm f/2.8 DG OS HSM Sports: This telephoto zoom lens is designed for action, sports, and wildlife photography.",
    content2:
      "⭐︎ Sigma 35mm f/2.8 DG DN Contemporary: This prime lens is designed for mirrorless cameras and is known for its compact size and high optical quality.",
    content3:
      "⭐︎ Sigma 14mm f/2.8 DG HSM Art: This ultra-wide prime lens is designed for landscape and astrophotography.",
    bannerImage: require("../assets/images/f2.webp"),
    images: [
      require("../assets/images/f22.webp"),
      require("../assets/images/f222.webp"),
      require("../assets/images/f2.webp"),
    ],
    price: 6200000,
    path: "/shop/",
  },

  {
    id: 27,
    category: "Accessory",
    title: "FS 300B",
    content:
      "⭐︎ The Nanlite FS-300B is a bi-color LED spotlight that runs on AC power and provides high-output at an affordable price for photo and video.",
    content1:
      "⭐︎ The FS-300B is a 350W Bi-color LED Spotlight with a CCT range of 2700K-6500K.",
    content2:
      "⭐︎ And it features an all-in-one design.",
    content3: "⭐︎ Compact and easy to use.",
    bannerImage: require("../assets/images/fs.webp"),
    images: [
      require("../assets/images/fs2.webp"),
      require("../assets/images/fs3.webp"),
      require("../assets/images/fs.webp"),
    ],
    price: 6200000,
    path: "/shop/",
  },
  {
    id: 28,
    category: "Accessory",
    title: "Boya Wireless Microphone",
    content:
      "⭐︎ Wireless System: Boya wireless microphones use radio frequency (RF) technology to transmit audio from the microphone to the receiver.",
    content1:
      "⭐︎ Lavalier and Handheld Options: Boya offers both lavalier (lapel) microphones and handheld wireless microphones.",
    content2:
      "⭐︎ UHF and 2.4GHz Systems: Boya provides wireless microphones with UHF (Ultra High Frequency) and 2.4GHz frequency options.",
    content3:
      "⭐︎ Long Battery Life: Boya wireless microphones often come with long-lasting battery life to ensure uninterrupted recording or performance.",
    bannerImage: require("../assets/images/boya.webp"),
    images: [
      require("../assets/images/boya2.webp"),
      require("../assets/images/boya3.webp"),
      require("../assets/images/boya.webp"),
    ],
    price: 6200000,
    path: "/shop/",
  },

  {
    id: 29,
    category: "Accessory",
    title: "Curved Reflectors",
    content:
      "⭐︎ A concave mirror is a mirror with an inward-curved surface.",
    content1:
      "⭐︎ A convex mirror, on the other hand, is a mirror with an outward-curved surface.",
    content2:
      "⭐︎ Changing the direction of light rays.",
    content3:
      "⭐︎ Curved surface that reflects light.",
    bannerImage: require("../assets/images/reflector.webp"),
    images: [
      require("../assets/images/reflector2.webp"),
      require("../assets/images/reflector3.webp"),
      require("../assets/images/reflector.webp"),
    ],
    price: 6200000,
    path: "/shop/",
  },

  {
    id: 30,
    category: "Accessory",
    title: "Camera Hard Cases",
    content:
      "⭐︎ Rugged Exterior: Camera hard cases typically have rugged exteriors made from materials like hard plastic.",
    content1:
      "⭐︎ Foam Inserts: Many camera hard cases come with customizable foam inserts.",
    content2:
      "⭐︎ Waterproof and Airtight: Some camera hard cases feature a rubber gasket seal that provides waterproof and airtight protection.",
    content3:
      "⭐︎ Lockable: Many camera hard cases are designed with double-throw latches that can be locked for added security.",
    bannerImage: require("../assets/images/case.webp"),
    images: [
      require("../assets/images/case2.webp"),
      require("../assets/images/case3.webp"),
      require("../assets/images/case.webp"),
    ],
    price: 6200000,
    path: "/shop/",
  },

  {
    id: 31,
    tag: "featured",
    category: "Accessory",
    title: "Seamless Papers",
    content:
      "⭐︎ Photography and Videography Backgrounds: Seamless papers are primarily used as backgrounds for portrait photography.",
    content1:
      "⭐︎ Sizes and Widths: Seamless papers are available in various sizes and widths, typically ranging from about 26 inches (66 cm) to 107 inches (272 cm) wide.",
    content2:
      "⭐︎ Wide Variety of Colors: They come in an extensive array of colors, including standard options like white, black, gray, and various shades of blue and green.",
    content3:
      "⭐︎ Reusable: While they can become marked or damaged over time, seamless papers are generally durable and can be reused for multiple shoots until they show signs of wear.",
    bannerImage: require("../assets/images/seam.webp"),
    images: [
      require("../assets/images/seam2.webp"),
      require("../assets/images/seam3.webp"),
      require("../assets/images/seam.webp"),
    ],
    price: 6200000,
    path: "/shop/",
  },

  {
    id: 32,
    tag: "featured",
    category: "Accessory",
    title: "Seamless Background Holder",
    content:
      "⭐︎ Background Support Stand: This is the main structure that holds and supports the background material.",
    content1:
      "⭐︎ Crossbars or Telescopic Poles: Crossbars or telescopic poles are used to span the width of the background and hold it in place.",
    content2:
      "⭐︎ Clamps or Clips: Clamps or clips are used to secure the background material to the crossbars.",
    content3:
      "⭐︎ Portability: Many background support systems are designed to be portable, with collapsible stands and lightweight materials.",
    bannerImage: require("../assets/images/holder.webp"),
    images: [
      require("../assets/images/holder2.webp"),
      require("../assets/images/holder3.webp"),
      require("../assets/images/holder.webp"),
    ],
    price: 6200000,
    path: "/shop/",
  },

  {
    id: 33,
    category: "Accessory",
    title: "Godox Light Bags",
    content:
      "⭐︎ They often feature padded interiors and durable exteriors.",
    content1:
      "⭐︎ Organization: These bags typically have various compartments, dividers, and pockets to help you organize and store your lighting gear efficiently.",
    content2:
      "⭐︎ Customization: Many Godox light bags offer customizable interiors, allowing you to adjust the compartments to fit the specific gear you need to carry.",
    content3:
      "⭐︎ Size Options: Godox offers light bags in various sizes to accommodate different lighting setups.",
    bannerImage: require("../assets/images/godox.webp"),
    images: [
      require("../assets/images/godox2.webp"),
      require("../assets/images/godox3.webp"),
      require("../assets/images/godox.webp"),
    ],
    price: 6200000,
    path: "/shop/",
  },

  {
    id: 34,
    category: "Accessory",
    title: "Green Screen Suits",
    content:
      "⭐︎ Sizing Options: Green screen suits are available in various sizes to accommodate different body types and heights.",
    content1:
      "⭐︎ Full-Body Coverage: These suits cover the entire body, including the face, hands, and feet.",
    content2:
      "⭐︎ Fabric: Green screen suits are made from a special green fabric that is chosen for its consistent color and texture.",
    content3:
      "⭐︎ Breathability and Comfort: Since the suits are typically used for extended periods in photography and video shoots.",
    bannerImage: require("../assets/images/suit.webp"),
    images: [
      require("../assets/images/suit.webp"),
      require("../assets/images/suit.webp"),
      require("../assets/images/suit.webp"),
    ],
    price: 6200000,
    path: "/shop/",
  },

  {
    id: 35,
    tag: "featured",
    category: "Accessory",
    title: "AD 600 Light Bulbs",
    content:
      "⭐︎ High Power Output: The Godox AD600 units are known for their high power output, often delivering up to 600 watt-seconds of energy.",
    content1:
      "⭐︎ Battery-Powered: AD600 units are portable and run on rechargeable lithium-ion batteries.",
    content2:
      "⭐︎ Flash Bulbs: The flash bulbs or tubes in AD600 units are essential components responsible for producing the intense bursts of light required for photography and lighting.",
    content3:
      "⭐︎ Cooling System: To prevent overheating during extended use, AD600 units are equipped with effective cooling systems to maintain consistent performance.",
    bannerImage: require("../assets/images/bulb.webp"),
    images: [
      require("../assets/images/bulb2.webp"),
      require("../assets/images/buld.webp"),
      require("../assets/images/bulb2.webp"),
    ],
    price: 6200000,
    path: "/shop/",
  },

  {
    id: 36,
    category: "Accessory",
    title: "Camera Bags",
    content:
      "⭐︎ Camera Backpacks: Backpack-style camera bags are versatile and comfortable for carrying camera gear.",
    content1:
      "⭐︎ Camera Shoulder Bags: Shoulder bags are designed for quick access to your camera and a few lenses.",
    content2:
      "⭐︎ Camera Sling Bags: Sling bags combine the benefits of backpacks and shoulder bags.",
    content3:
      "⭐︎ Camera Rolling Cases: Rolling cases have wheels and extendable handles for transporting heavy or extensive camera setups.",
    bannerImage: require("../assets/images/bag.webp"),
    images: [
      require("../assets/images/bag2.webp"),
      require("../assets/images/bag3.webp"),
      require("../assets/images/bag.webp"),
    ],
    price: 6200000,
    path: "/shop/",
  },

  {
    id: 37,
    category: "Accessory",
    title: "Reflectors",
    content:
      "⭐︎ A concave mirror is a mirror with an inward-curved surface.",
    content1:
      "⭐︎ A convex mirror, on the other hand, is a mirror with an outward-curved surface.",
    content2:
      "⭐︎ Changing the direction of light rays.",
    content3:
      "⭐︎ Curved surface that reflects light.",
    bannerImage: require("../assets/images/reflector11.webp"),
    images: [
      require("../assets/images/reflector22.webp"),
      require("../assets/images/reflector33.webp"),
      require("../assets/images/reflector.webp"),
    ],
    price: 6200000,
    path: "/shop/",
  },

  {
    id: 38,
    category: "Accessory",
    title: "H8 Voice Recorders",
    content:
      "⭐︎ 12 simultaneous recording tracks.",
    content1: "⭐︎ Interchangeable capsules.",
    content2:
      "⭐︎ App-driven touchscreen interface.",
    content3:
      "⭐︎ H8 is designed to meet all your audio recording needs.",
    bannerImage: require("../assets/images/voice.webp"),
    images: [
      require("../assets/images/voice2.webp"),
      require("../assets/images/voice3.webp"),
      require("../assets/images/voice.webp"),
    ],
    price: 6200000,
    path: "/shop/",
  },

  {
    id: 39,
    category: "Accessory",
    title: "Silicon Camera Covers",
    content: "⭐︎ Protection.",
    content1: "⭐︎ Weather Resistance.",
    content2: "⭐︎ Grip Enhancement.",
    content3: "⭐︎ Shock Absorption.",
    bannerImage: require("../assets/images/cover.webp"),
    images: [
      require("../assets/images/cover2.webp"),
      require("../assets/images/cover3.webp"),
      require("../assets/images/cover.webp"),
    ],
    price: 6200000,
    path: "/shop/",
  },

  {
    id: 40,
    category: "Accessory",
    title: "DJI RS3 Mini",
    content: "⭐︎ DJI RS 3 Mini is a small.",
    content1: "⭐︎ Lightweight.",
    content2: "⭐︎ Powerful payload capacity.",
    content3: "⭐︎ Handheld stabilizer.",
    bannerImage: require("../assets/images/rs.webp"),
    images: [
      require("../assets/images/rs2.webp"),
      require("../assets/images/rs3.webp"),
      require("../assets/images/rs.webp"),
    ],
    price: 6200000,
    path: "/shop/",
  },

  {
    id: 41,
    category: "Accessory",
    title: "Battery Pack",
    content: "⭐︎ Small.",
    content1: "⭐︎ Lightweight.",
    content2: "⭐︎ Durable.",
    content3: "⭐︎ Long lasting.",
    bannerImage: require("../assets/images/lp.webp"),
    images: [
      require("../assets/images/lp2.webp"),
      require("../assets/images/lp3.webp"),
      require("../assets/images/lp.webp"),
    ],
    price: 6200000,
    path: "/shop/",
  },

  {
    id: 42,
    category: "Accessory",
    title: "Canon Mount Adapter",
    content:
      "⭐︎ Compatibility: Canon produces a range of mount adapters designed to adapt lenses with various Canon mounts to other Canon mounts.",
    content1:
      "⭐︎ Maintaining Lens Functionality: In most cases, Canon mount adapters maintain the electrical connections and autofocus capabilities of the adapted lenses.",
    content2:
      "⭐︎ Image Quality: A well-designed Canon mount adapter should not significantly impact image quality.",
    content3:
      "⭐︎ Lens Compatibility: When using a Canon mount adapter, it's essential to ensure compatibility between the adapted lens and the camera system.",
    bannerImage: require("../assets/images/adapter.webp"),
    images: [
      require("../assets/images/adapter2.webp"),
      require("../assets/images/adapter3.webp"),
      require("../assets/images/adapter.webp"),
    ],
    price: 6200000,
    path: "/shop/",
  },
  {
    id: 43,
    category: "Camera",
    tag: "featured",
    title: "Canon 200D",
    content:
      "⭐︎ High-Definition Video: Records video in full HD (1080p) resolution, delivering high-quality video footage.",
    content1:
      "⭐︎ Built-in Wi-Fi: You can control remotely, transfer footage wirelessly, or live-stream using the built-in Wi-Fi capability.",
    content2:
      "⭐︎ Professional Audio: Features XLR audio inputs and provides professional-level audio recording with phantom power support for external microphones.",
    content3:
      "⭐︎ LCD Touchscreen: LCD touchscreen for easy menu navigation and monitoring of your shots.",
    bannerImage: require("../assets/images/200.webp"),
    images: [
      require("../assets/images/2001.webp"),
      require("../assets/images/2002.webp"),
      require("../assets/images/200.webp"),
    ],
    price: 6200000,
    path: "/shop/",
  },
  {
    id: 44,
    category: "Lenses",
    tag: "featured",
    title: "Lense Bags",
    content: "⭐︎ Small.",
    content1: "⭐︎ Lightweight.",
    content2: "⭐︎ Durable.",
    content3: "⭐︎ Long lasting.",
    bannerImage: require("../assets/images/lens.webp"),
    images: [
      require("../assets/images/lens2.webp"),
      require("../assets/images/lens3.webp"),
      require("../assets/images/lens.webp"),
    ],
    price: 6200000,
    path: "/shop/",
  },
  {
    id: 45,
    category: "Accessory",
    tag: "featured",
    title: "Sennheiser Wireless Microphone",
    content: "⭐︎ Small.",
    content1: "⭐︎ Lightweight.",
    content2: "⭐︎ Durable.",
    content3: "⭐︎ Long lasting.",
    bannerImage: require("../assets/images/mic.webp"),
    images: [
      require("../assets/images/mic1.webp"),
      require("../assets/images/mic2.webp"),
      require("../assets/images/mic.webp"),
    ],
    price: 6200000,
    path: "/shop/",
  },
  {
    id: 46,
    category: "Accessory",
    tag: "featured",
    title: "Voice Recorder H5",
    content:
      "⭐︎ Interchangeable Microphone Capsules.",
    content1: "⭐︎ Multiple Inputs.",
    content2: "⭐︎ High-Quality Preamps.",
    content3: "⭐︎ Removable SD Card.",
    bannerImage: require("../assets/images/recorder.webp"),
    images: [
      require("../assets/images/recorder1.webp"),
      require("../assets/images/recorder2.webp"),
      require("../assets/images/recorder.webp"),
    ],
    price: 6200000,
    path: "/shop/",
  },
  {
    id: 47,
    category: "Accessory",
    tag: "featured",
    title: "Panasonic Boom Mic",
    content: "⭐︎ Super sound recording.",
    content1: "⭐︎ High sensitivity.",
    content2: "⭐︎ Minimal noise.",
    content3:
      "⭐︎ Directional condenser microphone.",
    bannerImage: require("../assets/images/boom.webp"),
    images: [
      require("../assets/images/boom1.webp"),
      require("../assets/images/boom2.webp"),
      require("../assets/images/boom.webp"),
    ],
    price: 6200000,
    path: "/shop/",
  },
  {
    id: 48,
    category: "Accessory",
    tag: "featured",
    title: "C Stand",
    content: "⭐︎ Small.",
    content1: "⭐︎ Lightweight.",
    content2: "⭐︎ Durable.",
    content3: "⭐︎ Long lasting.",
    bannerImage: require("../assets/images/stand.webp"),
    images: [
      require("../assets/images/stand2.webp"),
      require("../assets/images/stand3.webp"),
      require("../assets/images/stand.webp"),
    ],
    price: 6200000,
    path: "/shop/",
  },
  {
    id: 49,
    category: "Accessory",
    tag: "featured",
    title: "Apture Video Light",
    content: "⭐︎ Small.",
    content1: "⭐︎ Lightweight.",
    content2: "⭐︎ Durable.",
    content3: "⭐︎ Long lasting.",
    bannerImage: require("../assets/images/apture.webp"),
    images: [
      require("../assets/images/apture2.webp"),
      require("../assets/images/apture3.webp"),
      require("../assets/images/apture.webp"),
    ],
    price: 6200000,
    path: "/shop/",
  },
  {
    id: 50,
    category: "Accessory",
    tag: "featured",
    title: "Wireless Triggers",
    content: "⭐︎ Wireless Communication.",
    content1: "⭐︎ Transmitter and Receiver.",
    content2: "⭐︎ Flash Triggering.",
    content3: "⭐︎ Multiple Channels.",
    bannerImage: require("../assets/images/trigger.webp"),
    images: [
      require("../assets/images/trigger2.webp"),
      require("../assets/images/trigger3.webp"),
      require("../assets/images/trigger.webp"),
    ],
    price: 6200000,
    path: "/shop/",
  },
  {
    id: 51,
    category: "Accessory",
    title: "Camera Gimbals",
    content:
      "⭐︎ Stabilization: The primary function of a camera gimbal is to stabilize the camera and lens to reduce vibrations.",
    content1:
      "⭐︎ Compatibility: Camera gimbals are designed to accommodate a range of camera sizes and weights.",
    content2:
      "⭐︎ Battery Life: Battery life varies among gimbals, with some offering several hours of operation on a single charge.",
    content3:
      "⭐︎ Smartphone App Compatibility: Many modern gimbals can be controlled and customized via smartphone apps.",
    bannerImage: require("../assets/images/gimbal.webp"),
    images: [
      require("../assets/images/gimbal2.webp"),
      require("../assets/images/gimbal3.webp"),
      require("../assets/images/gimbal.webp"),
    ],
    price: 6200000,
    path: "/shop/",
  },
  {
    id: 52,
    category: "Accessory",
    title: "Phone Gimbals",
    content:
      "⭐︎ Stabilization: The primary purpose of a phone gimbal is to stabilize your smartphone while shooting videos or photos.",
    content1:
      "⭐︎ Compatibility: Ensure that the phone gimbal you choose is compatible with your smartphone's size and weight.",
    content2:
      "⭐︎ App Integration: Many gimbals have companion apps that offer additional features and controls, such as time-lapse and motion tracking.",
    content3:
      "⭐︎ Battery Life: Check the battery life of the gimbal, as it can vary between models.",
    bannerImage: require("../assets/images/phone.webp"),
    images: [
      require("../assets/images/phone1.webp"),
      require("../assets/images/phone3.webp"),
      require("../assets/images/phone.webp"),
    ],
    price: 6200000,
    path: "/shop/",
  },
  {
    id: 53,
    category: "Camera",
    title: "Canon Mark IV",
    content:
      "⭐︎ High-Definition Video: Records video in full HD (1080p) resolution, delivering high-quality video footage.",
    content1:
      "⭐︎ Built-in Wi-Fi: Transfer footage wirelessly, or live-stream using the built-in Wi-Fi capability.",
    content2:
      "⭐︎ Professional Audio: Provides professional-level audio recording with phantom power support for external microphones.",
    content3:
      "⭐︎ LCD Touchscreen: LCD touchscreen for easy menu navigation and monitoring of your shots.",
    bannerImage: require("../assets/images/mark.webp"),
    images: [
      require("../assets/images/mark1.webp"),
      require("../assets/images/mark2.webp"),
      require("../assets/images/mark.webp"),
    ],
    price: 6200000,
    path: "/shop/",
  },
  {
    id: 54,
    category: "Accessory",
    title: "LED Lights",
    content:
      "⭐︎ Energy Efficiency: LED lights are highly energy-efficient, using significantly less electricity compared to traditional incandescent or fluorescent lights.",
    content1:
      "⭐︎ Longevity: LEDs have a long operational life, often lasting tens of thousands of hours before needing replacement.",
    content2:
      "⭐︎ Instant Lighting: LEDs light up instantly with full brightness when turned on, unlike some other types of lights that may take a moment to reach full illumination.",
    content3:
      "⭐︎ Color Temperature: LEDs are available in various color temperatures, ranging from warm white to cool white.",
    bannerImage: require("../assets/images/led.webp"),
    images: [
      require("../assets/images/led2.webp"),
      require("../assets/images/led3.webp"),
      require("../assets/images/led.webp"),
    ],
    price: 6200000,
    path: "/shop/",
  },
  {
    id: 55,
    category: "Accessory",
    title: "RODE Wireless GO 2",
    content:
      "⭐︎ Dual-Channel Wireless Microphone System: The Rode Wireless GO 2 is a compact and highly versatile dual-channel wireless microphone system.",
    content1:
      "⭐︎ Built-In Microphones: Both transmitters have built-in omnidirectional microphones, allowing you to capture audio directly without additional external microphones.",
    content2:
      "⭐︎ Dual-Channel Receiver: The receiver features two microphone inputs, one for each transmitter, allowing you to capture audio from both sources simultaneously.",
    content3:
      "⭐︎ High-Quality Audio: The Wireless GO 2 system is known for delivering high-quality audio, thanks to Rode's expertise in microphone technology.",
    bannerImage: require("../assets/images/go.webp"),
    images: [
      require("../assets/images/go1.webp"),
      require("../assets/images/go3.webp"),
      require("../assets/images/go.webp"),
    ],
    price: 6200000,
    path: "/shop/",
  },
  {
    id: 56,
    category: "Accessory",
    title: "Boya Microphone Windshields",
    content:
      "⭐︎ Wind Noise Reduction: Boya microphone windshields are primarily used to reduce wind noise and other unwanted sounds caused by moving air during outdoor recording.",
    content1:
      "⭐︎ Foam Windshields: Boya typically offers foam windshields that fit over the microphone capsule.",
    content2:
      "⭐︎ Compatibility: Boya microphone windshields are usually designed to fit specific Boya microphone models or other microphones with similar dimensions.",
    content3:
      "⭐︎ Easy Installation: Boya windshields are easy to install; they simply slide over the microphone's capsule.",
    bannerImage: require("../assets/images/wind.webp"),
    images: [
      require("../assets/images/wind2.webp"),
      require("../assets/images/wind3.webp"),
      require("../assets/images/wind.webp"),
    ],
    price: 6200000,
    path: "/shop/",
  },
  {
    id: 57,
    category: "Accessory",
    tag: "featured",
    title: "Popup Green Screens",
    content:
      "⭐︎ Creating pop-up green screen effects involves recording your subject in front of a green screen and separately recording or creating the pop-up element.",
    content1:
      "⭐︎ Fine-tune the color correction, lighting, and shadows to make the pop-up element blend seamlessly with the background.",
    content2:
      "⭐︎ Set up a green or blue screen backdrop behind your subject. Make sure it's evenly lit to ensure a clean key.",
    content3:
      "⭐︎ Remember that achieving a professional look may require practice and attention to detail in terms of lighting, color correction, and keying.",
    bannerImage: require("../assets/images/popup.webp"),
    images: [
      require("../assets/images/popup2.webp"),
      require("../assets/images/popup3.webp"),
      require("../assets/images/popup.webp"),
    ],
    price: 6200000,
    path: "/shop/",
  },
  {
    id: 58,
    category: "Accessory",
    tag: "featured",
    title: "AD 600 BM",
    content:
      "⭐︎ All-in-One Outdoor Flash is powerful and portable with professional lithium battery pack.",
    content1:
      "⭐︎ This versatile light offers 600Ws with nine steps of output, ranging from 1/1 to 1/256.",
    content2:
      "⭐︎ 600Ws GN87 High Speed Sync Outdoor Flash Strobe Light with X1T-S Wireless Flash Trigger.",
    content3:
      "⭐︎ Optical and Built-in 2.4G wireless transmission to offer stable output.",
    bannerImage: require("../assets/images/ad.webp"),
    images: [
      require("../assets/images/ad2.webp"),
      require("../assets/images/ad3.webp"),
      require("../assets/images/ad.webp"),
    ],
    price: 6200000,
    path: "/shop/",
  },
  {
    id: 59,
    category: "Accessory",
    tag: "featured",
    title: "Boom Poles",
    content:
      "⭐︎ A boom pole is a long, extendable, and often lightweight pole used in the film and television industry for various purposes.",
    content1:
      "⭐︎ Primarily to position a microphone close to the source of sound without it being visible in the shot.",
    content2:
      "⭐︎ Boom poles are commonly used by boom operators or sound recordists to capture high-quality audio in film, TV shows, documentaries, and other video productions.",
    content3:
      "⭐︎ Boom poles are typically made from lightweight and durable materials, such as aluminum or carbon fiber, to make them easy to handle and carry on set.",
    bannerImage: require("../assets/images/poles.webp"),
    images: [
      require("../assets/images/pole2.webp"),
      require("../assets/images/pole3.webp"),
      require("../assets/images/poles.webp"),
    ],
    price: 6200000,
    path: "/shop/",
  },
  {
    id: 60,
    category: "Accessory",
    tag: "featured",
    title: "Atem Mini Pro",
    content:
      "⭐︎ ATEM Mini Pro is fast to set up and easy to use.",
    content1:
      "⭐︎ It includes 4 standards converted HDMI inputs, USB webcam out, HDMI out.",
    content2:
      "⭐︎ Powerful live switcher with 4 HDMI inputs, hardware streaming engine, recording to USB disks, USB webcam out, audio mixer, 2D DVE.",
    content3:
      "⭐︎ HDMI Live Stream Switcher featuring 4-Channel Live Stream HDMI Switcher.",
    bannerImage: require("../assets/images/atem.webp"),
    images: [
      require("../assets/images/atem2.webp"),
      require("../assets/images/atem3.webp"),
      require("../assets/images/atem.webp"),
    ],
    price: 6200000,
    path: "/shop/",
  },
  {
    id: 61,
    category: "Accessory",
    tag: "featured",
    title: "Light Soft Boxes",
    content:
      "⭐︎ Softboxes have a box-like design with a reflective interior and a diffusing front panel.",
    content1:
      "⭐︎ They are used to control and shape light.",
    content2:
      "⭐︎ Reducing harsh shadows and providing a flattering and even illumination for your subject.",
    content3:
      "⭐︎ Softboxes are commonly used in studio setups for portrait photography, product photography, and video shoots.",
    bannerImage: require("../assets/images/box.webp"),
    images: [
      require("../assets/images/box2.webp"),
      require("../assets/images/box3.webp"),
      require("../assets/images/box.webp"),
    ],
    price: 6200000,
    path: "/shop/",
  },
  {
    id: 62,
    category: "Accessory",
    tag: "featured",
    title: "Video Tube Lights",
    content:
      "⭐︎ LED tube lights are a popular choice for video production and photography.",
    content1:
      "⭐︎ They are energy-efficient and provide a consistent, flicker-free source of lighting.",
    content2:
      "⭐︎ Some tube lights are designed to be portable and battery-powered, making them ideal for on-location shoots and outdoor settings.",
    content3:
      "⭐︎ Some tube lights offer tunable white light options, allowing you to adjust the color temperature to match various lighting conditions.",
    bannerImage: require("../assets/images/video.webp"),
    images: [
      require("../assets/images/video2.webp"),
      require("../assets/images/video3.webp"),
      require("../assets/images/video.webp"),
    ],
    price: 6200000,
    path: "/shop/",
  },
  {
    id: 63,
    category: "Camera",
    tag: "featured",
    title: "Sony HDR CX405",
    content:
      "⭐︎ The camcorder features a 1/5.8-inch Exmor R CMOS sensor.",
    content1:
      "⭐︎ The HDR-CX405 comes with a 30x optical zoom, which allows you to get closer to the action.",
    content2:
      "⭐︎ The camcorder features Optical SteadyShot with Intelligent Active Mode, which helps reduce camera shake and ensures stable footage even when shooting while moving.",
    content3:
      "⭐︎ It records video and still images to a Memory Stick Micro (M2) or microSD/microSDHC/microSDXC memory card.",
    bannerImage: require("../assets/images/cx.webp"),
    images: [
      require("../assets/images/cx2.webp"),
      require("../assets/images/cx3.webp"),
      require("../assets/images/cx.webp"),
    ],
    price: 6200000,
    path: "/shop/",
  },
];
