import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import {
  VscGrabber,
  VscClose,
} from "react-icons/vsc";
import "./Navbar.css";

function NavBar() {
  const [click, setClick] = useState(false);

  const handleClick = () => setClick(!click);

  return (
    <>
      <nav className="navbar">
        <div className="nav-container">
          <NavLink to="/" className="nav-logo">
            AUSLUK LTD
          </NavLink>

          <ul
            className={
              click
                ? "nav-menu active"
                : "nav-menu"
            }
          >
            <NavLink to="/featured">
              <li
                className="nav-item nav-links"
                onClick={handleClick}
              >
                Featured
              </li>
            </NavLink>
            <NavLink to="/shop">
              <li
                className="nav-item nav-links"
                onClick={handleClick}
              >
                Cameras
              </li>
            </NavLink>

            <NavLink to="/lense">
              <li
                className="nav-item nav-links"
                onClick={handleClick}
              >
                Lenses
              </li>
            </NavLink>
            <NavLink to="/accessory">
              <li
                className="nav-item nav-links"
                onClick={handleClick}
              >
                Accesories
              </li>
            </NavLink>
            <NavLink to="/about">
              <li
                className="nav-item nav-links"
                onClick={handleClick}
              >
                About Us
              </li>
            </NavLink>
          </ul>
          <div
            className="nav-icon"
            onClick={handleClick}
          >
            {click ? (
              <VscClose />
            ) : (
              <VscGrabber />
            )}
          </div>
        </div>
      </nav>
    </>
  );
}

export default NavBar;
