import { Col } from "react-bootstrap";
import "../../../Home/components/HomeProducts/featured.css"

import {
  NavLink,
  useNavigate,
} from "react-router-dom";
import {
  useEffect,
  useState,
  useContext,
} from "react";
import { useParams } from "react-router-dom";
import { FiShoppingCart } from "react-icons/fi";
import sanityClient from "../../../../utils/client.js"

const TopCard = () => {
  const navigate = useNavigate();
  const [products, setProducts] = useState(null);

  useEffect(() => {
    sanityClient
      .fetch(
        `*[_type == "top"]{
        _id,
        name,
        price,
        image{
          asset->{
          url
          },
      },
      hexCode,
    }`
      )
      .then((data) => setProducts(data))
      .catch(console.error);
  }, []);

  return (
    <>
      <div
        className="heading"
        style={{
          fontSize: "25px",
          lineHeight: "55px",
          textAlign: "center",
          fontFamily: "Times New Roman",
          marginTop: "50px",
          color: "#FF6347",
        }}
      >
        <h1>Top Rated</h1>
      </div>
      {products?.map((product) => {
        return (
          <Col
            md={2}
            sm={4}
            xs={5}
            className="product"
            key={product._id}
          >
            <img
              loading="lazy"
              //   onClick={() =>
              //     navigate(
              //       `/favorites/${product._id}`
              //     )
              //   }
              src={product?.image.asset.url}
              alt=""
            />
            <div className="product-details">
              <h3>{product?.name}</h3>
              <div className="price">
                <h3>
                  UGX{" "}
                  {product?.price.toLocaleString()}
                </h3>
                <NavLink>
                  <FiShoppingCart
                    size={20}
                    color="#fe679a"
                  />
                </NavLink>
              </div>
            </div>
          </Col>
        );
      })}
    </>
  );
};

export default TopCard;
