import sanityClient from "@sanity/client";
import imageUrlBuilder from "@sanity/image-url";

export default sanityClient({
  projectId: "asn0econ",
  dataset: "production",
  apiVersion: "2022-10-29",
  useCdn: true,
  token:
    "sk7H3L85yV3TpwH16oFsUC1z9j7rYqvAyb7YQfV14GPN4q1kYKtvdMlth47Kb4ELQR19y8aFKTJvDqHHdBrK4bM53wXaxB1eeCq3NeitVra3nc2DWq5mnuyzhRansS4A9qAe9eLikuoKKPRTMn0CdVByyWISdfjKqc9V1IH9enR8ngS9QHFz",
});
const builder = imageUrlBuilder(sanityClient);

export const urlFor = (source) =>
  builder.image(source);
