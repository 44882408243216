import ScrollToTop from "react-scroll-to-top";

function ScrollTop() {
  return (
    <div>
      <div style={{ marginTop: "2vh" }} />
      <ScrollToTop smooth />
    </div>
  );
}

export default ScrollTop;
