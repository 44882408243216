import React from "react";
import "./Splash.css";

const SplashScreen = () => {
  return (
    <div className="splash-screen">
      <img
        src={require("../../assets/logo.png")}
        alt="App Logo"
        width={250}
        style={{ borderRadius: "150px" }}
      />
    </div>
  );
};

export default SplashScreen;
